body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
  background-color: aliceblue;
}

/* App */

/* body > #root > div {
  height: 100vh;
} */

body {
  padding-top: 45px;
  background-color: #f3f4f8 !important;
  font-family: "Lato", sans-serif !important;
  color: black;
  font-size: 1rem;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.App {
  background-color: white !important;
  text-align: left;
  /* max-width: 375px; */
  max-width: 768px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.yacha-logo {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  margin-top: -45px;
}

.icons img {
  max-height: 2rem;
}

.container {
  background-color: white;
  padding-bottom: 1rem;
}

/* Login page */
.flex-container {
  background-color: #f3f4f8;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.75rem;
}
.flex-container,
.login-cotainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: white;
  box-shadow: 0 5px 10px -10px grey;
}

.login-header {
  font-family: "Lato light";
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
}

.login-header .icons {
  margin-left: auto;
}

.logos {
  margin-left: auto;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  width: 100%;
}
.form-control,
input {
  height: 48px;
}

button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hide-show-password-eye {
  margin-left: -40px !important;
  background-color: white !important;
  border-left: none !important;
  z-index: 9999 !important;
}

.hide-show-password-eye svg {
  color: gray;
}

.login-container .btn-primary:disabled {
  background-color: #27a8d1;
  border: #27a8d1;
}

.login-container .btn-primary {
  background-color: #27a8d1;
  border: #27a8d1;
}
.login-container .form-check-label.text-primary {
  color: #27a8d1 !important;
}

/* Yacht */
.tasks-container {
  box-shadow: 0 5px 10px -10px grey;
  padding-bottom: 2rem;
}

.box-yachts {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.box-rucknahme.task-aktive {
  font-weight: bold;
  color: #000;
}

.box-rucknahme,
.stop-task-dialog,
.finish-task-dialog,
.warning-task-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem 1rem;
  flex-wrap: nowrap;
}

.box-ruckname-and-icon {
  display: flex;
  max-width: 45%;
  flex: 50% 1;
}

.box-ruckname-and-icon .stateIcon {
  margin-left: auto;
}

.box-rucknahme.task-aktive {
  background-color: #e3f0ff;
}

.box-rucknahme {
  color: #797979;
}

.yacht .card-header {
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.yachtname {
  display: flex;
  font-weight: bolder;
  font-size: 110%;
}
.yachtname .close {
  margin-left: auto;
}
.yacht .yacht-infos {
  color: gray;
}

.yacht.card.task-active {
  border: 1px solid #ed971c;
}

.yacht.card.yacht.card.task-active .card-header {
  background-color: #ed971c;
}
.yacht.card.task-done .card-header {
  background-color: #d1edd3;
}

.yacht.card .card-header {
  background-color: #f6f6f6;
}
.yacht.card.task-done,
.yacht.card.task-done .box-rucknahme,
.box-rucknahme.task-done {
  background-color: #e0fee2;
}

.yacht.card.closed .close,
.yacht.card.closed .card-body {
  display: none;
}

.yacht.card.doingByOther {
  background-color: #fafafa;
}
.box-yachtname {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.stateIcon .orange {
  border: #ed971c;
  color: #ed971c;
}
.stateIcon .green {
  color: green;
}

.yacht.card.true .details-vie,
.yacht.card.true .close,
.yacht.card.false .yacht-state,
.yacht.card.false .status-action {
  display: inherit;
}

.yacht.card.false .details-view,
.yacht.card.false .close,
.yacht.card.true .yacht-state,
.yacht.card.false .status-action {
  display: none;
}

/* user */
.user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: gray;
  grid-gap: 1rem;
  gap: 1rem;
}
.box-user {
  padding: 2rem 0;
}

.username {
  font-size: 1.5rem;
}

.greeting,
.username {
  color: #000;
}

/* Navbar */
.navbar-header,
.offcanvas-header {
  background-color: #bee4f7 !important;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

.box-languages .header {
  font-weight: bold;
}

.box-languages .nav-link.item {
  padding-left: 10px;
}

.btn-open.btn-primary {
  background-color: #ed971c;
  border: #ed971c;
}

.finish-task-dialog {
  background-color: #faf0a4;
}

.finish-task-no {
  color: red;
  text-decoration: underline;
}

.finish-task-yes {
  color: green;
  text-decoration: underline;
}

.stop-task-dialog {
  background-color: #fff0f5;
  color: red;
}

.stop-task-no {
  color: green;
  text-decoration: underline;
}

.stop-task-yes {
  text-decoration: underline;
}

.task-by-other {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.btn-success {
  background-color: #27a744 !important;
  border-color: #27a744 !important;
}

.btn.font-weight-bold {
  font-weight: bold;
}

.btn {
  padding: 0 0.75rem !important;
}

.warning-task-dialog {
  display: flex;
  justify-content: space-between;
}
.offline .warning-task-dialog {
  display: flex;
  justify-content: center;
}

.notification {
  background-color: #faf0a4;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}

.warning-task-dialog {
  padding: 2rem 1rem;
}
.warning-task-ok {
  color: green;
}

.card.card-closed .box-rucknahme button {
  display: none;
}

.team .role::after {
  content: ", ";
}

.team .role:last-of-type:after {
  content: "";
}

.card-body {
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  margin-top: 1rem;
}

.navbar-bottom {
  position: fixed;
  bottom: 0;
  color: gray;
  font-size: 10px;
  padding-bottom: 0.5rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  order: 10;
}

.card-container.task-active {
  order: 1;
}

/* No Tasks */
.no-tasks .card-body {
  border: none;
  margin: auto;
}

/* logout as button in navbar*/
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  color: white;
  margin: auto;
  text-align: center;
  width: 100%;
}

/* Calendar */

.calendar {
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.react-calendar {
  margin: auto;
  margin-bottom: 1em;
  border: none !important;
}

.calendar input {
  font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
  text-align: center;
}

.datePicker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.today {
  padding-top: 0.5em;
}

.previousDay,
.nextDay,
.calendar input {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 6px 10px 5px;
  border: 1px solid lightgray;
}

.previousDay:hover,
.nextDay:hover,
.calendar input:hover {
  opacity: 0.7;
}

.react-datepicker-wrapper {
  margin: 1em 0 !important;
}

.react-datepicker__input-container {
  text-align: center;
}

.nav-link:focus,
.nav-link:hover {
  color: white !important;
}

